<template>
  <div>
    <heading2
      :heading="balanceLabel"
      class="mb-0"
    />
    <b-card v-if="!noDataPresent">
      <b-row>
        <b-col cols="2">
          <p class="form-label">
            <strong>{{balanceTypeLabel}}</strong>
            <br>
            {{balanceTypeData}}
          </p>
        </b-col>
        <b-col cols="10">
          <b-form-row class="h-100 flex-nowrap">
            <b-col class="d-flex">
              <card-accent
                value-card
                :title="incomeDataTitle"
                :cardValue="incomeDataValue"
                :cardPercentage="incomeDataPercentage"
              />
            </b-col>
            <b-col class="d-flex">
              <card-accent
                value-card
                :title="purchasesDataTitle"
                :cardValue="purchasesDataValue"
                :cardPercentage="purchasesDataPercentage"
              />
            </b-col>
            <b-col class="d-flex">
              <card-accent
                value-card
                :title="molDataTitle"
                :cardValue="molDataValue"
                :cardPercentage="molDataPercentage"
              />
            </b-col>
            <b-col class="d-flex">
              <card-accent
                value-card
                :title="profitLossDataTitle"
                :cardValue="profitLossDataValue"
                :cardPercentage="profitLossDataPercentage"
              />
            </b-col>
            <b-col class="d-flex">
              <card-accent
                value-card
                :title="assetsDataTitle"
                :cardValue="assetsDataValue"
                :cardPercentage="assetsDataPercentage"
              />
            </b-col>
<!--            <b-col class="d-flex">-->
<!--              <card-accent-->
<!--                value-card-->
<!--                :title="entepriseTitle"-->
<!--                :cardValue="entepriseValue"-->
<!--                :cardPercentage="enteprisePercentage"-->
<!--              />-->
<!--            </b-col>-->

          </b-form-row>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { isEmpty } from 'lodash';
import { formatDateOnly } from '@/utils/formatterHelper';
import { isPresent } from '@/utils/validators';
import Heading2 from '@/components/clickCard/Heading2.vue';
import CardAccent from '@/components/clickCard/CardAccent.vue';

export default {
  name: 'BalanceData',
  components: { Heading2, CardAccent },
  props: {
    data: Object,
  },
  computed: {
    noDataPresent() {
      return isEmpty(this.data);
    },
    balanceLabel() {
      return `Ultimo Bilancio: ${this.balanceLastData}`;
    },
    balanceDepDateLabel() {
      return 'Data di deposito:';
    },
    balanceDepDateData() {
      if (this.getScoringData().depositDate) {
        const a = new Date(this.getScoringData().depositDate);
        return formatDateOnly(a, 'it');
        // const a = this.getScoringData().closeDate * 0.001;
        // return moment(a).format("DD/MM/YYYY");
      }
      return this.notAvailableMsg;
    },
    balanceLastData() {
      if (this.getScoringData()?.lastBalanceDate) {
        const a = new Date(this.getScoringData().lastBalanceDate);
        return formatDateOnly(a, 'it');
        // const a = this.getScoringData().closeDate * 0.001;
        // return moment(a).format("DD/MM/YYYY");
      }
      return this.notAvailableMsg;
    },
    balanceTypeLabel() {
      return 'Tipologia di bilancio:';
    },
    balanceTypeData() {
      if (isPresent(this.getScoringData()?.balanceType)) {
        return this.getScoringData().balanceType;
      }
      return this.notAvailableMsg;
    },
    incomeDataTitle() {
      return 'FATTURATO:';
    },
    incomeDataValue() {
      let value;
      if (this.getScoringData().balanceRevenue) {
        value = this.getScoringData().balanceRevenue;
      }
      return value || this.notAvailableMsg;
    },
    incomeDataPercentage() {
      if (this.getScoringData().balanceRevenueDelta) {
        const current = this.getScoringData().balanceRevenueDelta;
        if (current) return current.toFixed(3);
      }
      return null;
    },
    purchasesDataTitle() {
      return 'ACQUISTI:';
    },
    purchasesDataValue() {
      let value;
      if (this.getScoringData().balancePurchases) {
        value = this.getScoringData().balancePurchases;
      }
      return value || this.notAvailableMsg;
    },
    purchasesDataPercentage() {
      if (this.getScoringData().balancePurchasesDelta) {
        const current = this.getScoringData().balancePurchasesDelta;
        if (current) return current.toFixed(3);
      }

      return null;
    },
    molDataTitle() {
      return 'MOL:';
    },
    molDataValue() {
      let value;
      if (this.getScoringData().balanceMol) {
        value = this.getScoringData().balanceMol;
      }
      return value || this.notAvailableMsg;
    },
    molDataPercentage() {
      if (this.getScoringData().balanceMolDelta) {
        const current = this.getScoringData().balanceMolDelta;
        if (current) return current.toFixed(3);
      }
      return null;
    },
    profitLossDataTitle() {
      return 'UTILE/PERDITA:';
    },
    profitLossDataValue() {
      let value;
      if (this.getScoringData().balanceProfitLoss) {
        value = this.getScoringData().balanceProfitLoss;
      }
      return value || this.notAvailableMsg;
    },
    profitLossDataPercentage() {
      if (this.getScoringData().balanceProfitLossDelta) {
        const current = this.getScoringData().balanceProfitLossDelta;
        if (current) return current.toFixed(3);
      }
      return null;
    },
    assetsDataTitle() {
      return 'PATRIMONIO:';
    },
    assetsDataValue() {
      let value;
      if (this.getScoringData()?.balanceAssets) {
        value = this.getScoringData().balanceAssets;
      }
      return value || this.notAvailableMsg;
    },
    assetsDataPercentage() {
      if (this.getScoringData().balanceAssetsDelta) {
        const current = this.getScoringData().balanceAssetsDelta;
        if (current) return current.toFixed(3);
      }
      return null;
    },
    entepriseTitle() {
      return 'ENTERPRISE VALUE:';
    },
    entepriseValue() {
      let value;
      // if (this.getScoringData()?.totalLiability) {
      //   value = this.getScoringData().totalLiability.totalShareholdersEquity.totalShareholdersEquity;
      // }
      return value || this.notAvailableMsg;
    },
    enteprisePercentage() {
      // if (this.getScoringData()?.totalLiability) {
      //   const current = this.getScoringData().totalLiability.totalShareholdersEquity.totalShareholdersEquity;
      //   if (this.balanceDetail(1)?.totalLiability) {
      //     const previous = this.balanceDetail(1).totalLiability.totalShareholdersEquity.totalShareholdersEquity;
      //     if (current && previous) {
      //       const value = (current - previous) / previous;
      //       return value.toFixed(3);
      //     }
      //   }
      // }
      return null;
    },
  },
  methods: {
    getScoringData() {
      return this.data?.scoring?.clickSection || {};
    },
  },
};
</script>

<style scoped>

</style>
