<template>
  <div>
    <div v-if="hasData">
      <balance-data :data="data" />
      <scoring-gauge
        :data="data"
      />
      <b-form-row class="h-100 flex-nowrap mt-3">
        <b-col cols="6" class="d-flex">
          <card-accent cta-card :title="creditLimitLabel" :subtitle="creditLimitData" />
        </b-col>
        <b-col cols="3" class="d-flex">
          <card-accent
            spotCard
            :spotValue="protestsSpotValue"
            :title="protestsDataTitle"
            :not-available="protestNotAvailable"
            doubleLineHeader
          />
        </b-col>
        <b-col cols="3" class="d-flex">
          <card-accent
            spotCard
            :spotValue="procsSpotValue"
            :title="procsDataTitle"
            :not-available="procsNotAvailable"
            doubleLineHeader
          />
        </b-col>
      </b-form-row>
<!--      <b-row>-->
<!--        <b-col xl="9" class="overflow-hidden">-->
<!--        </b-col>-->
<!--        <b-col xl="3" class="mt-3 mt-lg-0 d-lg-flex flex-lg-column justify-content-lg-center py-lg-5">-->
<!--          <b-form-row-->
<!--            class="h-100 d-flex flex-lg-column justify-content-lg-evenly align-items-stretch py-lg-3"-->
<!--          >-->
<!--            <b-col cols="6" lg="12" class="flex-grow-1">-->
<!--              <b-card class="card&#45;&#45;cta h-100" body-class="d-flex flex-column justify-content-center">-->
<!--                <div class="card&#45;&#45;cta__title">{{creditLimitLabel}}</div>-->
<!--                <div class="card&#45;&#45;cta__subtitle">{{creditLimitData}}</div>-->
<!--              </b-card>-->
<!--            </b-col>-->
<!--          </b-form-row>-->
<!--        </b-col>-->
<!--      </b-row>-->
    </div>
    <div v-else class="py-3">
      <p><em>Nessun dato di dashboard disponibile.</em></p>
    </div>
  </div>
</template>

<script>
import { isNotEmpty } from '@/utils/validators';
import { formatDecimalNumber } from '@/utils/formatterHelper';
import BalanceData from '@/components/clickCard/BalanceData.vue';
import CardAccent from '@/components/clickCard/CardAccent.vue';

const ScoringGauge = () => import('@/components/scoringGauge/ScoringGauge.vue');

export default {
  name: 'ProfileTabsDashboardMerits',
  components: { CardAccent, BalanceData, ScoringGauge },
  props: {
    data: Object,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      chartPlaceholder: require('@/assets/img/Report-CRP-chart_placeholder.png'),
    };
  },
  computed: {
    notAvailableMsg() {
      return 'N.D.';
    },
    hasData() {
      return isNotEmpty(this.data);
    },
    creditLimitLabel() {
      return 'LIMITE DI CREDITO';
    },
    creditLimitData() {
      // console.log('this.data credit', this.data);
      if (isNotEmpty(this.data?.scoring?.creditLimit)) {
        // const val = formatCurrencyHuman(this.data.scoring.creditLimit, 'en', 'EUR');
        const val = formatDecimalNumber(this.data.scoring.creditLimit, 0, 'it');
        // console.log('inte', this.data.scoring.creditLimit, int);
        // const val = formatCurrency(this.data.scoring.creditLimit, 'it', 'EUR');
        return `${val} EUR`;
      }
      return this.notAvailableMsg;
    },
    feedbackDataTitle() {
      return 'SEGNALAZIONI CRP';
    },
    pieValueOk() {
      const a = this.data?.score?.reportReceived?.globalVotes?.countPositive;
      const b = this.data?.score?.reportReceived?.globalVotes?.countTotal;
      const baseCheck = a / b;
      if (a > 0 && b > 0) return `${baseCheck * 100}%`;
      return this.notAvailableMsg;
    },
    pieValueKo() {
      const a = this.data?.score?.reportReceived?.globalVotes?.countNegative;
      const b = this.data?.score?.reportReceived?.globalVotes?.countTotal;
      const baseCheck = a / b;
      if (a > 0 && b > 0) return `${baseCheck * 100}%`;
      return this.notAvailableMsg;
    },
    pieValueKoBase360() {
      const a = this.data?.score?.reportReceived?.globalVotes?.countNegative;
      const b = this.data?.score?.reportReceived?.globalVotes?.countTotal;
      return (a / b) * 360;
    },
    pieSliceFirstSize() {
      if (this.pieValueKoBase360 > 180) return 'transform:rotate(180deg);';
      return `transform:rotate(${this.pieValueKoBase360}deg);`;
    },
    pieSecondSliceCheck() {
      return this.pieValueKoBase360 > 180;
    },
    pieSliceSecondSize() {
      const computed = this.pieValueKoBase360 - 180;
      return `transform:rotate(${computed}deg);`;
    },
    pieContainerClasses() {
      const baseClasses = 'pieContainer';
      if (this.data?.score?.reportReceived?.globalVotes?.countTotal === 0) return `${baseClasses} has-no-votes`;
      return baseClasses;
    },
    protestsDataTitle() {
      return 'PROTESTI';
    },
    protestsSpotValue() {
      return !this.data?.additionalInfos?.protestFound;
    },
    protestNotAvailable() {
      return this.data?.additionalInfos?.protestFound === undefined;
    },
    procsDataTitle() {
      return 'FALLIMENTI E PROCEDURE';
    },
    procsSpotValue() {
      return !this.data?.scoring?.clickSection?.hasEvents;
    },
    procsNotAvailable() {
      return this.data?.scoring?.clickSection?.hasEvents === undefined;
    },

  },
  methods: {},
};
</script>

<style scoped>

</style>
